import { useState } from "react";
import formatString from "format-string-by-pattern";
import { getSsnTitleAndStringPatternByNearestCountryField } from "../lib";

export function useSocialSecurityField(field: any, block: any, onChangeFn: any) {
  const { title, ssnStringPattern } = getSsnTitleAndStringPatternByNearestCountryField(field, block);

  const getFormattedValue = (value: string) =>
    formatString(ssnStringPattern, value.replace(/[^0-9]/g, "")).slice(0, 11);

  const [value, setValue] = useState(getFormattedValue(field.value || ""));

  const handleChange = (e: any) => {
    const formattedValue = getFormattedValue(e.target.value);
    setValue(formattedValue);
    onChangeFn(field, formattedValue);
  };

  return {
    title,
    value,
    handleChange,
  };
}
